<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/9
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        选择地址
      </div>
    </top-bar-arrow>
    <div class="main">
      <van-list
              v-model="loading"
              :finished="isFinsh"
              finished-text="没有更多了"
              @load="initAddressList"
      >
        <template v-for="(item, index) in addressList">
          <div class="address-item-wrap" :key="index">
            <div class="address-item box-shadow" :key="index">
              <div class="left">
                <div class="line1">
                  {{ item.pcr_address }}
                  <van-icon name="edit" size="16" color="#F2BF86" @click="editAddress(item)" style="margin-left: 2px;"/>
                </div>
                <div class="line2">{{ item.address }}</div>
                <div class="line3">
                  <span class="text1">{{ item.contacts }}</span>
                  <span class="text2">{{ item.contact_phone }}</span>
                </div>
              </div>
              <div class="right">
                <div class="select-icon" :class="{'active': selectAddressIndex == index}" @click="selectChange(index)"></div>
              </div>
            </div>
          </div>
        </template>
      </van-list>
    </div>
    <div class="btn-warp">
      <div class="btn-tj" @click="addressAdd">新增收货地址</div>
    </div>
  </div>
</template>

<script>
  import { getAddressList } from '@/common/api/address.js'
  import topBarArrow from '../../components/top-bar-arrow'
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    components: {
      topBarArrow
    },
    data() {
      return {
        addressList: [],
        selectAddressIndex: -1,
        selectAddressObject: '',
        page: 1,
        isFinsh: false,
        loading: false
      };
    },
    computed: {
      ...mapGetters({
        chooseAddress: 'getChooseAddress'
      })
    },
    watch: {
      chooseAddress: {
        handler (now) {
          this.selectAddressObject = {...now}
          console.log(now)
          this.initAddressList()
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {

    },
    activated () {
      this.selectAddressObject = {...this.chooseAddress}
      console.log(this.selectAddressObject)
      this.initAddressList()
    },
    methods:{
      ...mapMutations(['UPDATE_CHOOSE_ADDRESS', 'UPDATE_EDIT_ADDRESS']),
      selectChange (index) {
        this.selectAddressIndex = index
        this.selectAddressObject = this.addressList[index]

        this.beforeBack()
      },
      initAddressList () {
        getAddressList({
          data: {
            page: this.page
          },
          success: res => {
            if (res.code == 200) {
              this.addressList = this.addressList.concat(res.data.list)
              this.loading = false;
              this.page = this.page + 1
              if (this.addressList.length == res.data.total) {
                this.isFinsh = true
              }
              this.selectAddressIndex = this.addressList.findIndex(item => item.id == this.selectAddressObject.id)
            }
          }
        })
      },
      // 新增地址
      addressAdd () {
        this.$router.push({
          name: 'addressAdd'
        })
      },
      // 编辑地址
      editAddress (item) {
        this.UPDATE_EDIT_ADDRESS(item)
        this.$router.push({
          name: 'addressAddEdit'
        })
      },
      beforeBack () {
        this.UPDATE_CHOOSE_ADDRESS(this.selectAddressObject)
        this.$router.go(-1)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "./addressChoose.scss"
</style>

