import baseUtil from "../js/utils.js"

// 收货地址列表
export const getAddressList = (data) => baseUtil.sendRequest({
	url: '/Address/userList',
	...data
})

// 省级列表
export const getProvinceList = (data) => baseUtil.sendRequest({
	url: '/Region/getProvinceList',
	...data
})

// (根据上级ID)获取省市区列表
export const getSublist = (data) => baseUtil.sendRequest({
	url: '/Region/getSublist',
	...data
})

// 添加用户收货地址(签发时所需)
export const addAddress = (data) => baseUtil.sendRequest({
	url: '/Address/add',
	...data
})

//修改收货地址
export const editAddress = (data) => baseUtil.sendRequest({
	url: '/Address/setAddress',
	...data
})

//删除收货地址
export const deleteAddress = (data) => baseUtil.sendRequest({
	url: '/Address/del',
	...data
})